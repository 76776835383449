import { ContextProvider } from '@lit/context';
import context from '@common/contexts/services-context.js';

export const Provider = Base =>
  class extends Base {
    constructor() {
      super();
      this._provider = new ContextProvider(this, { context, initialValue: {} });
    }

    provideServices(services = {}) {
      this._provider.setValue(services);
    }
  };
