/** @deprecated */
import { PopoverManagerApi } from '@services/popovermanager/api/PopoverManagerApi.js';
import { executePromise } from '@utils/executePromise.js';

const priv = Symbol('priv');

export class PopoverManager {
  constructor(toast, enabledContent) {
    this[priv] = {
      popoverManagerApi: new PopoverManagerApi(toast, enabledContent),
    };
  }

  /**
   * Attach a DOM node that will wrap all popovers
   * @param {*} el DOM node
   */
  attachWrapper(el) {
    this[priv].popoverManagerApi._popoverWrapperEl = el;
  }

  presentPopover(
    componentName,
    { title, anchor, orientation, outsideTapEvents, properties, events, preventCancel } = {},
  ) {
    return executePromise(() => {
      const { popoverManagerApi } = this[priv];
      const header = title === undefined ? undefined : { title };

      return popoverManagerApi.present(componentName, {
        popover: {
          anchor,
          orientation,
          header,
        },
        outsideTapEvents,
        properties,
        events,
        preventCancel,
      });
    });
  }

  presentPopoverList({
    title,
    anchor,
    orientation,
    items, // array of {id, title, type, className, checked, include, disabled, selectAction, checkedChanged}
    direction, // direction of the listbox 'vertical' or 'horizontal'
  } = {}) {
    return executePromise(() => {
      const cachedIDs = {};
      const assertCorrectMenuItem = ({ id, title }) => {
        if (typeof id !== 'string' || id.length === 0) {
          throw Error(
            'item options in PopoverManager.presentPopoverList() config must have a string id.',
          );
        }

        if (cachedIDs[id]) {
          throw Error(
            `id '${id}' is duplicated in PopoverManager.presentPopoverList() config. Must be unique.`,
          );
        }

        cachedIDs[id] = true;

        if (typeof title !== 'string' || title.length === 0) {
          throw Error(
            `menu item option with id ${id} in PopoverManager.presentPopoverList() is either missing a title property, or the title needs a non empty string.`,
          );
        }

        return true;
      };

      const selectItems = itemArray =>
        itemArray
          .filter(
            item =>
              Array.isArray(item) ||
              (assertCorrectMenuItem(item) && (item.include === undefined || item.include)),
          )
          .map(item => (Array.isArray(item) ? selectItems(item) : item));

      const toHandlers = (result, item) => {
        if (Array.isArray(item)) {
          return item.reduce(toHandlers, result);
        }

        result[item.id] = args => {
          if (typeof item.selectAction === 'function') {
            return item.selectAction();
          }
          if (typeof item.checkedChanged === 'function') {
            return item.checkedChanged(args);
          }
          if (typeof item.selectionChanged === 'function') {
            return item.selectionChanged(args);
          }

          return false;
        };
        return result;
      };

      const toMenuItems = item => {
        if (Array.isArray(item)) {
          return item.map(toMenuItems);
        }

        return item;
      };

      const selectedItems = selectItems(items);
      const handlers = selectedItems.reduce(toHandlers, {});
      const menuItems = selectedItems
        .map(toMenuItems)
        .filter(item => (Array.isArray(item) && item.length > 0) || !Array.isArray(item));

      import('@components/vst-ui-listbox/vst-ui-listbox.js');
      return this.presentPopover('vst-ui-listbox', {
        anchor,
        orientation,
        properties: {
          direction,
          view: {
            heading: title,
            items: menuItems,
            icons: false,
          },
        },
        events: ({ completeWorkflow }) => ({
          'list-item-clicked': event => {
            completeWorkflow(event.detail);
          },
          'list-switch-changed': event => {
            const [id, checked] = event.detail;
            handlers[id](checked); // component.fire('complete-workflow', event.detail);
          },
          'list-item-option-selected': event => {
            const [id, value] = event.detail;
            handlers[id](value);
          },
        }),
      }).then(result => {
        if (result && result.cancelled) {
          return result;
        }

        const id = result;
        return handlers[id]();
      });
    });
  }

  presentDialog(
    componentName,
    { title, preventCancel, preventCancelFromScreen, properties, events } = {},
  ) {
    return executePromise(() => {
      const { popoverManagerApi } = this[priv];
      const header = title === undefined ? undefined : { title };

      return popoverManagerApi.present(componentName, {
        popover: {
          type: 'dialog',
          header,
        },
        preventCancel,
        preventCancelFromScreen,
        properties,
        events,
      });
    });
  }

  closeAll() {
    const { popoverManagerApi } = this[priv];

    popoverManagerApi.destroyAll();
  }

  closeLast() {
    const { popoverManagerApi } = this[priv];
    popoverManagerApi.popPopover();
  }

  hasPopovers() {
    const { popoverManagerApi } = this[priv];

    return popoverManagerApi.hasPopovers();
  }

  hasDialogs() {
    const { popoverManagerApi } = this[priv];

    return popoverManagerApi.hasDialogs();
  }

  getPopoverSelectorDebugInfo() {
    const { popoverManagerApi } = this[priv];

    return popoverManagerApi.getPopoverSelectorDebugInfo();
  }
}
